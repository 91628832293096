import React from "react";
import loadable from "@loadable/component";
import Layout from "../components/layout";

const BreadcrumbModule = loadable(() => import("../components/BreadcrumbModule/BreadcrumbModule"));
const StaticDetailIntro = loadable(() => import("../components/StaticDetailIntro/StaticDetailIntro"));
const StaticDetailDesc = loadable(() => import("../components/StaticDetailDesc/StaticDetailDesc"));
const FeaturedSlider = loadable(() => import("../components/FeaturedSlider/FeaturedSlider"));

const StaticDetail = (props) => {
    return (
        <Layout>
            <div className="layout-padding-top"></div>

            <BreadcrumbModule />

            <StaticDetailIntro />

            <StaticDetailDesc />

            <FeaturedSlider 
                tag="static-detail"
            />
        </Layout>
    )
}

export default StaticDetail